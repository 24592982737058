<template>
  <div class="main clearfix">

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";

import axios from "@/api/apis";

export default {
  name: "Relation",
  created() {},
  setup() {
    const state = reactive({
      authorize: ""
    });
    const loadArticle = () => {
      axios
        .post("")
        .then(function(data) {


        })
        .catch(function(error) {
          console.log(error);
        });
    };

    onMounted(() => {
      loadArticle();
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style scoped>
</style>
